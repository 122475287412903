import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const claimVuex = createNamespacedHelpers("claim");
import vailcode from "@utils/errcode";
export default {
  name: "popClaimOrders",
  props: {},
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "connect", "address"]),
    ...claimVuex.mapState(["claimList", "condition", "logLoading", "logHasMore"])
  },
  data() {
    return {
      isFlag: false
    };
  },
  watch: {
    "$parent.popOrderShow": {
      handler(bool) {
        if (bool) {
          this.onLoadData(1);
        }
      },
      deep: true,
      immediate: true
    },
    claimList: {
      handler(newVla, oldVal) {},
      deep: true,
      immediate: true
    }
  },
  async mounted() {},
  methods: {
    ...claimVuex.mapActions(["getClaimList"]),
    ...claimVuex.mapMutations(["setCondition"]),
    checkScroll() {
      const scrollHeight = this.$refs.myScroll.scrollHeight;
      const scrollTop = this.$refs.myScroll.scrollTop;
      const clientHeight = this.$refs.myScroll.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight - 20) {
        // 接近底部时触发加载  
        if (this.logHasMore && !this.logLoading) {
          let page = this.condition.page;
          this.onLoadData(page + 1);
        }
      }
    },
    onLoadData(page) {
      this.setCondition({
        "owner": this.address,
        "pageSize": 15,
        "page": page
      });
      this.getClaimList([() => {}, () => {}]);
    },
    async onClaim(item) {
      if (this.isFlag) return;
      this.isFlag = true;
      item.isLoading = true;
      let rs = await this.$http.post("stake/order/claim", {
        owner: item.staker,
        order_id: item.order_id
      });
      this.isFlag = false;
      item.isLoading = false;
      vailcode(rs, async () => {
        try {
          item.is_lock = 1;
          await this.$parent._doClaim(rs);
        } catch (eee) {}
      });
    },
    checkOrderStatus(event, orderId) {
      const element = event.target;
      element.style.animation = 'pop-ref 1s linear forwards';
      setTimeout(() => {
        element.style.animation = ''; // 移除动画属性  
      }, 2000);
    },
    refresh() {
      const element = this.$refs.popRefresh;
      element.style.animation = 'pop-ref 1s linear forwards';
      setTimeout(() => {
        element.style.animation = ''; // 移除动画属性  
      }, 2000);
      this.$refs.myScroll.scrollTo({
        top: 0,
        left: 0
      });
    }
  }
};