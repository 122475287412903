var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "level-content-item-content",
    class: _vm.isMobile && 'level-content-item-content-wap'
  }, [_c('div', {
    staticClass: "line-item"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$lang('address')))]), _c('div', {
    staticClass: "copy-item"
  }, [_c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm._f("fmt_address")(_vm.item.staker, 20)))]), _c('div', {
    directives: [{
      name: "copy",
      rawName: "v-copy",
      value: _vm.item.staker,
      expression: "item.staker"
    }],
    staticClass: "copy"
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none"
    }
  }, [_c('path', {
    attrs: {
      "d": "M4.66667 3.99998V1.99998C4.66667 1.82317 4.7369 1.6536 4.86193 1.52858C4.98695 1.40355 5.15652 1.33331 5.33333 1.33331H13.3333C13.5101 1.33331 13.6797 1.40355 13.8047 1.52858C13.9298 1.6536 14 1.82317 14 1.99998V11.3333C14 11.5101 13.9298 11.6797 13.8047 11.8047C13.6797 11.9297 13.5101 12 13.3333 12H11.3333V14C11.3333 14.368 11.0333 14.6666 10.662 14.6666H2.67133C2.58342 14.6672 2.49626 14.6503 2.41488 14.6171C2.3335 14.5838 2.25949 14.5348 2.19711 14.4728C2.13472 14.4109 2.0852 14.3372 2.05137 14.2561C2.01754 14.1749 2.00009 14.0879 2 14L2.002 4.66665C2.002 4.29865 2.302 3.99998 2.67333 3.99998H4.66667ZM3.33533 5.33331L3.33333 13.3333H10V5.33331H3.33533ZM6 3.99998H11.3333V10.6666H12.6667V2.66665H6V3.99998Z",
      "fill": "white"
    }
  })])])])]), _c('div', {
    staticClass: "line-item"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$lang('Base Compute')))]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm._f("toPrecision")(_vm.item.total_hashrate, 2)))])]), _c('div', {
    staticClass: "line-item"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$lang('Lower level Compute')))]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm._f("toPrecision")(_vm.item.umbrella_total_hashrate, 2)))])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };