var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "height": "auto",
      "width": _vm.isMobile ? '90vw' : '460px'
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "pop__intro"
  }, [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "btns"
  }, [_vm.showCancelBtn ? _c('div', {
    staticClass: "btn btn-cancel",
    on: {
      "click": _vm.cancelFn
    }
  }, [_vm._v(_vm._s(!_vm.cancelTxt ? _vm.$lang("Cancel") : _vm.cancelTxt))]) : _vm._e(), _vm.showConfirmBtn ? _c('div', {
    staticClass: "btn btn-confirm",
    on: {
      "click": _vm.confirmFn
    }
  }, [_vm._v(_vm._s(!_vm.confirmTxt ? _vm.$lang("Continue") : _vm.confirmTxt))]) : _vm._e()])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };