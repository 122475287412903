import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "popPromptCompute",
  props: {
    title: {
      type: String,
      default: ""
    },
    descs: {
      type: Array,
      default: []
    },
    otherInfo: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...walletVuex.mapState(["isMobile"])
  },
  data() {
    return {};
  },
  methods: {
    cancelFn() {
      // this.$emit("cancel");

      this.$emit("update:show", false);
      this.$emit("closeFn");
    },
    confirmFn() {
      this.$emit("confirm");
    }
  }
};