var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "stake",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "stake-main"
  }, [_c('div', {
    staticClass: "s1"
  }, [_c('div', {
    staticClass: "s1-main"
  }, [_c('div', {
    staticClass: "s1-left"
  }, [_c('div', {
    staticClass: "video-box"
  }, [_c('video', {
    attrs: {
      "autoplay": "",
      "loop": "",
      "muted": "",
      "playsinline": ""
    },
    domProps: {
      "muted": true
    }
  }, [_c('source', {
    attrs: {
      "src": require('@images/video.mp4'),
      "type": "video/mp4"
    }
  })])])]), _c('div', {
    staticClass: "s1-right"
  }, [_c('div', {
    staticClass: "s1__title"
  }, [_vm._v(_vm._s(_vm.$lang(`Purchase Node`)))]), _c('div', {
    staticClass: "s1-list"
  }, [_c('div', {
    staticClass: "s1-number"
  }, [_c('div', {
    staticClass: "btn-n",
    class: _vm.buyNum <= 1 && 'dis',
    on: {
      "click": _vm.onMin
    }
  }, [_vm._v("MIN")]), _c('div', {
    staticClass: "btn-m",
    class: _vm.buyNum <= 1 && 'dis',
    on: {
      "click": _vm.onSub
    }
  }, [_vm._v("-")]), _c('div', {
    staticClass: "s1-input"
  }, [_c('div', {
    staticClass: "s1-input-main"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.buyNum,
      expression: "buyNum"
    }],
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.buyNum
    },
    on: {
      "keyup": function ($event) {
        return _vm.buyNumKeyUp();
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.buyNum = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "btn-m",
    class: _vm.isMax && 'dis',
    on: {
      "click": _vm.onAdd
    }
  }, [_vm._v("+")]), _c('div', {
    staticClass: "btn-n",
    class: _vm.isMax && 'dis',
    on: {
      "click": _vm.onMax
    }
  }, [_vm._v("Max")])]), _c('div', {
    staticClass: "s1-inviter"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inviter,
      expression: "inviter"
    }],
    attrs: {
      "type": "text",
      "placeholder": _vm.$lang('Enter Your Reference Address')
    },
    domProps: {
      "value": _vm.inviter
    },
    on: {
      "keyup": function ($event) {
        return _vm.buyNumKeyUp();
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.inviter = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "s1-value"
  }, [_c('div', {
    staticClass: "s1-val"
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang(`Total Amount: `)))]), _vm._v(_vm._s(_vm._f("toThousands")(_vm.priceLabel)) + " USDT")])]), _c('div', {
    staticClass: "btn-box"
  }, [_vm.address ? [_c('div', {
    staticClass: "content-button-wrapper"
  }, [Number(_vm.nftSellInfo.startTime) > Number(_vm.nftSellInfo.nowTime) ? [_c('div', {
    staticClass: "content-button-wrapper"
  }, [_c('div', {
    staticClass: "btn-buy dis"
  }, [_vm._v(_vm._s(_vm.$lang(`Waiting to begin`)))])])] : Number(_vm.nftSellInfo.openTotalMint) <= Number(_vm.nftSellInfo.mintTotal) ? [_vm._v(" " + _vm._s(_vm.$lang(`Mint End`)) + " ")] : [!_vm.token_enough ? [_c('div', {
    staticClass: "btn-buy dis"
  }, [_vm._v(_vm._s(_vm.$lang(`USDT balance is insufficient`)))])] : [_vm.isApprove ? [_c('div', {
    staticClass: "content-button-wrapper",
    staticStyle: {
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "connect-button-buy connect-button-common",
    on: {
      "click": function ($event) {
        return _vm.onBuy();
      }
    }
  }, [_vm.isLoading ? [_c('svg-icon', {
    attrs: {
      "name": "icon-refresh",
      "spin": ""
    }
  })] : _vm._e(), _vm._v(" " + _vm._s(_vm.$lang(`Buy`)) + " ")], 2)])] : [_c('div', {
    staticClass: "content-button-wrapper",
    staticStyle: {
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "connect-button-buy connect-button-common",
    on: {
      "click": function ($event) {
        return _vm.onApprove();
      }
    }
  }, [_vm.isLoading ? [_c('svg-icon', {
    attrs: {
      "name": "icon-refresh",
      "spin": ""
    }
  })] : _vm._e(), _vm._v(" " + _vm._s(_vm.$lang(`Approve`)) + " ")], 2)])]]]], 2), _vm.nftSellInfo.userMint > 0 || _vm.userBurnAmount >= 500 ? _c('div', {
    directives: [{
      name: "copy",
      rawName: "v-copy",
      value: `${_vm.getHost}?t=${_vm.address}`,
      expression: "`${getHost}?t=${address}`"
    }],
    staticClass: "invite-box"
  }, [_c('span', [_vm._v(_vm._s(_vm.$lang(`Copy My Referral Link`)))]), _c('svg-icon', {
    attrs: {
      "name": "icon-copy"
    }
  })], 1) : _vm._e()] : [_c('div', {
    staticClass: "content-button-wrapper",
    staticStyle: {
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "connect-button-buy connect-button-common",
    on: {
      "click": _vm.doConnect
    }
  }, [_vm._v("Connect Wallet")])])]], 2)])])])]), _c('div', {
    staticClass: "s2"
  }, [_c('div', {
    staticClass: "s2-main"
  }, [_c('div', {
    staticClass: "s2-list"
  }, [_c('div', {
    staticClass: "s2-item item-1"
  }, [_c('div', {
    staticClass: "s2-item__icon"
  }, [_c('img', {
    attrs: {
      "src": require('@images/s2-icon-1.png')
    }
  })]), _c('div', {
    staticClass: "s2-item__info"
  }, [_c('div', {
    staticClass: "s2-item__label"
  }, [_vm._v(_vm._s(_vm.nftSellInfo.userMint))]), _c('div', {
    staticClass: "s2-item__title"
  }, [_vm._v(_vm._s(_vm.$lang(`My Nodes`)))])])]), _c('div', {
    staticClass: "s2-item item-2"
  }, [_c('div', {
    staticClass: "s2-item__icon"
  }, [_c('img', {
    attrs: {
      "src": require('@images/s2-icon-2.png')
    }
  })]), _c('div', {
    staticClass: "s2-item__info"
  }, [_c('div', {
    staticClass: "s2-item__label"
  }, [_vm._v(_vm._s(_vm.nftSellInfo.inviteCount))]), _c('div', {
    staticClass: "s2-item__title"
  }, [_vm._v(_vm._s(_vm.$lang(`Referral Person`)))])])]), _c('div', {
    staticClass: "s2-item item-3"
  }, [_c('div', {
    staticClass: "s2-item__icon"
  }, [_c('img', {
    attrs: {
      "src": require('@images/s2-icon-3.png')
    }
  })]), _c('div', {
    staticClass: "s2-item__info"
  }, [_c('div', {
    staticClass: "s2-item__label"
  }, [_vm._v(_vm._s(_vm._f("toThousands")(_vm.nftSellInfo.inviterReward_Label)) + " USDT")]), _c('div', {
    staticClass: "s2-item__title"
  }, [_vm._v(_vm._s(_vm.$lang(`Referral Rewards`)))])])]), _c('div', {
    staticClass: "s2-item item-4"
  }, [_c('div', {
    staticClass: "s2-item__icon"
  }, [_c('img', {
    attrs: {
      "src": require('@images/s2-icon-4.png')
    }
  })]), _c('div', {
    staticClass: "s2-item__info"
  }, [_c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('div', {
    staticClass: "s2-item__label"
  }, [_vm._v(_vm._s(_vm.userTotalAmount))]), _c('div', {
    staticStyle: {
      "flex-grow": "1"
    }
  }), _c('AutoBursh')], 1), _c('div', {
    staticClass: "s2-item__title"
  }, [_vm._v(" " + _vm._s(_vm.$lang(`Token`)) + " "), _c('div', {
    staticClass: "s3-item-btns"
  }, [_c('div', {
    staticClass: "s3-item-btn btn1",
    on: {
      "click": function ($event) {
        return _vm.viewOrder();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang(`Record`)) + " ")]), _c('div', {
    staticClass: "s3-item-btn",
    on: {
      "click": function ($event) {
        return _vm.onClaim();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang(`Apply`)) + " "), _vm.claimLoading ? [_c('svg-icon', {
    attrs: {
      "name": "icon-refresh",
      "spin": ""
    }
  })] : [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow1"
    }
  })]], 2)])])])])])])]), _c('LayoutCenter'), _c('div', {
    staticClass: "rule"
  }, [_c('div', {
    staticClass: "rule-main"
  }, [_c('div', {
    staticClass: "rule__tabs"
  }, [_c('div', {
    staticClass: "tab",
    class: _vm.selectTab == 0 && 'active',
    on: {
      "click": function ($event) {
        return _vm.onSelectTab(0);
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang('Node Intro')))]), _c('div', {
    staticClass: "tab",
    class: _vm.selectTab == 1 && 'active',
    on: {
      "click": function ($event) {
        return _vm.onSelectTab(1);
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang('Referral Details')))]), _c('div', {
    staticClass: "tab",
    class: _vm.selectTab == 2 && 'active',
    on: {
      "click": function ($event) {
        return _vm.onSelectTab(2);
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang('Activity History')))])]), _vm.selectTab == 0 ? _c('div', {
    staticClass: "rule__content"
  }, [_c('h2', [_vm._v(_vm._s(_vm.$lang('About Reflection Node')))]), _c('h3', [_vm._v(_vm._s(_vm.$lang('Node Sale Method:')))]), _c('ul', [_c('li', [_vm._v(" " + _vm._s(_vm.$lang(`Price Increment: The price increases by 25 USDT for every 100 nodes sold in the first 2000,after 2000 nodes, the price increases by 25 USDT for every 400 nodes sold. After 10,000 to 30,000 nodes, the price increases by 25 USDT for every 200 nodes sold.`)) + " ")])]), _c('h3', [_vm._v(_vm._s(_vm.$lang('Purchase Limit:')) + " ")]), _c('ul', [_c('li', [_vm._v(_vm._s(_vm.$lang('Each address can purchase a maximum of 10 nodes.')))])]), _c('h3', [_vm._v(_vm._s(_vm.$lang('Invitation Mechanism:')))]), _c('ul', [_c('li', [_vm._v(_vm._s(_vm.$lang(`Invitation Reward: Node holders receive 10%/1% of the invitee's purchased nodes, with rewards distributed across two levels, totaling 11%.`)) + " ")]), _c('li', [_vm._v(" " + _vm._s(_vm.$lang(`Invite others to stake, improve your node ranking, and earn a share of all token LP transaction fees.`)) + " ")])]), _c('h3', [_vm._v(_vm._s(_vm.$lang('Node Privileges:')))]), _c('ul', [_c('li', [_vm._v(_vm._s(_vm.$lang(`Mining Earnings: All node investors earn tokens from mining activities.`)))]), _c('li', [_vm._v(_vm._s(_vm.$lang(`Whitelist Privileges: Early access to ecosystem projects, such as IDO participation, WL rights, and exclusive airdrops.`)) + " ")]), _c('li', [_vm._v(" " + _vm._s(_vm.$lang(`Ecosystem Governance: Node investors gain governance rights, becoming early builders, contributors, and decision-makers.`)) + " ")])])]) : _vm._e(), _vm.selectTab == 1 ? _c('div', {
    staticClass: "rule__content"
  }, [_c('ReferralDetailView')], 1) : _vm._e(), _vm.selectTab == 2 ? _c('div', {
    staticClass: "rule__content"
  }, [_c('h2', [_vm._v(_vm._s(_vm.$lang(`Referrer List`)))]), _c('div', {
    staticClass: "sub-tit"
  }, [_vm._v(_vm._s(_vm.$lang(`First-level Referral`)))]), _c('div', {
    staticClass: "list"
  }, [_c('div', {
    staticClass: "item hd"
  }, [_c('div', {
    staticClass: "col addr"
  }, [_vm._v(_vm._s(_vm.$lang(`Address`)))]), _c('div', {
    staticClass: "col status"
  }, [_vm._v(_vm._s(_vm.$lang(`Status`)))]), _c('div', {
    staticClass: "col Commission"
  }, [_vm._v(_vm._s(_vm.$lang(`Commission Amount`)))]), _c('div', {
    staticClass: "col time"
  }, [_vm._v(_vm._s(_vm.$lang(`Time`)))])]), _vm._l(_vm.firstListData.list, function (item, index) {
    return _c('div', {
      staticClass: "item"
    }, [_c('div', {
      staticClass: "col addr"
    }, [_vm._v(_vm._s(_vm._f("fmt_address")(item.receiver, 30)))]), _c('div', {
      staticClass: "col status",
      class: `status-${item.status}`
    }, [item.status == 0 ? [_vm._v(_vm._s(_vm.$lang(`Processing`)))] : _vm._e(), item.status == 1 ? [_vm._v(_vm._s(_vm.$lang(`Success`)))] : _vm._e(), item.status == 2 ? [_vm._v(_vm._s(_vm.$lang(`Failed`)))] : _vm._e()], 2), _c('div', {
      staticClass: "col Commission"
    }, [_vm._v(_vm._s(item.commission))]), _c('div', {
      staticClass: "col time"
    }, [_vm._v(_vm._s(item.created_at))])]);
  })], 2), _c('div', {
    staticClass: "pages"
  }, [_c('my-pagination', {
    attrs: {
      "total": _vm.firstListData.total,
      "page-size": _vm.firstCondition.pageSize,
      "page": _vm.firstCondition.page,
      "on-page-change": _vm.onFirstPageChange,
      "on-page-size-change": _vm.onFirstPageSizeChange
    }
  })], 1), _c('div', {
    staticClass: "sub-tit"
  }, [_vm._v(_vm._s(_vm.$lang(`Second-level Referral`)))]), _c('div', {
    staticClass: "list"
  }, [_c('div', {
    staticClass: "item hd"
  }, [_c('div', {
    staticClass: "col addr"
  }, [_vm._v(_vm._s(_vm.$lang(`Address`)))]), _c('div', {
    staticClass: "col status"
  }, [_vm._v(_vm._s(_vm.$lang(`Status`)))]), _c('div', {
    staticClass: "col Commission"
  }, [_vm._v(_vm._s(_vm.$lang(`Commission Amount`)))]), _c('div', {
    staticClass: "col time"
  }, [_vm._v(_vm._s(_vm.$lang(`Time`)))])]), _vm._l(_vm.secondListData.list, function (item, index) {
    return _c('div', {
      staticClass: "item"
    }, [_c('div', {
      staticClass: "col addr"
    }, [_vm._v(_vm._s(_vm._f("fmt_address")(item.receiver, 30)))]), _c('div', {
      staticClass: "col status",
      class: `status-${item.status}`
    }, [item.status == 0 ? [_vm._v(_vm._s(_vm.$lang(`Processing`)))] : _vm._e(), item.status == 1 ? [_vm._v(_vm._s(_vm.$lang(`Success`)))] : _vm._e(), item.status == 2 ? [_vm._v(_vm._s(_vm.$lang(`Failed`)))] : _vm._e()], 2), _c('div', {
      staticClass: "col Commission"
    }, [_vm._v(_vm._s(item.commission))]), _c('div', {
      staticClass: "col time"
    }, [_vm._v(_vm._s(item.created_at))])]);
  })], 2), _c('div', {
    staticClass: "pages"
  }, [_c('my-pagination', {
    attrs: {
      "total": _vm.secondListData.total,
      "page-size": _vm.secondCondition.pageSize,
      "page": _vm.secondCondition.page,
      "on-page-change": _vm.onSecondPageChange,
      "on-page-size-change": _vm.onSecondPageSizeChange
    }
  })], 1)]) : _vm._e()])])], 1), _c('pop-prompt', {
    attrs: {
      "show": _vm.popInviterTipShow,
      "title": _vm.popTipContent,
      "showCancelBtn": false,
      "confirmTxt": _vm.$lang('Ok')
    },
    on: {
      "update:show": function ($event) {
        _vm.popInviterTipShow = $event;
      },
      "cancel": function ($event) {
        _vm.popInviterTipShow = false;
      },
      "confirm": function ($event) {
        _vm.popInviterTipShow = false;
      }
    }
  }), _c('pop-claim-orders', {
    attrs: {
      "show": _vm.popOrderShow
    },
    on: {
      "update:show": function ($event) {
        _vm.popOrderShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };