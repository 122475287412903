/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./popClaimOrders.vue?vue&type=template&id=51c3d96b&scoped=true"
import script from "./popClaimOrders.vue?vue&type=script&lang=js"
export * from "./popClaimOrders.vue?vue&type=script&lang=js"
import style0 from "./popClaimOrders.vue?vue&type=style&index=0&id=51c3d96b&prod&lang=scss&scoped=true"
import style1 from "./popClaimOrders.vue?vue&type=style&index=1&id=51c3d96b&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51c3d96b",
  null
  
)

export default component.exports