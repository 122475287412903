import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const purchaseVuex = createNamespacedHelpers("private");
import BigNumber from "bignumber.js";
import loadConfig from "@Web3WalletConfig/config.json";
import cookie from "js-cookie";
export default {
  name: "purchase",
  computed: {
    ...walletVuex.mapState(["isMobile", "isMobile", "connect", "address"])
  },
  data() {
    return {
      password: "",
      inviter: "",
      list: []
    };
  },
  watch: {
    address: {
      handler() {
        this.inviter = this.address;
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {},
  methods: {
    async doSearch() {
      this.list = [];
      let rs = await this.$http.post("/chainlist", {
        inviter: this.inviter,
        password: this.password
      });
      if (rs.code == 0) {
        this.list = rs.data;
      }
    }
  }
};