import ComputeView from "@components/ComputeView.vue";
import StakeView from "@components/StakeView.vue";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  components: {
    StakeView,
    ComputeView
  },
  computed: {
    ...walletVuex.mapState(["isMobile"])
  }
};