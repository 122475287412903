
import loadConfig from "@Web3WalletConfig/config.json";
import BigNumber from "bignumber.js";
import vailcode from "@utils/errcode";
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        claimList: [],
        logHasMore : false,
        logLoading : false,
        condition:{
            page:1,
            pageSize:15
        }
    },
    mutations: {
        setCondition: (state, payload) => {
            state.condition = payload
        },
        setLogLoading:(state, payload) => {
            state.logLoading = payload
        },
        setLogHasMore:(state, payload) => {
            state.logHasMore = payload
        },
        setClaimList: (state, payload) => {
            state.claimList = payload;
        },
        appendClaimList:(state, payload) =>{
            state.claimList = state.claimList.concat(payload)
        },
    },
    actions: {
        getClaimList: async ({state,commit, dispatch}) => {
            if (state.logLoading) return;
            commit('setLogLoading', true);
            let params =  state.condition
            let rs = await Vue.prototype.$http.post("stake/claim/logs" ,params);
            vailcode(rs, () => {
                rs.data.list.map(item=>{
                    item.amount_format =  BigNumber(item.amount).div(10 ** loadConfig.rect_token_decimals).toFixed(2)
                    item.isLoading = false
                    return item;
                })
                if(rs.data.pageNo == 1){
                  commit("setClaimList", rs.data.list);
                }
                else{
                  commit('appendClaimList', rs.data.list);
                }
                if(rs.data.totalPage > rs.data.pageNo ){
                  commit('setLogHasMore', true);
                }
                else{
                  commit('setLogHasMore', false);
                }
                commit('setLogLoading', false);
                typeof callback=='function' && callback(rs.data)
            },
            ()=>{
              typeof failcallback=='function' && failcallback(rs)
            });
        },
    }
}