var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content-button-wrapper",
    class: _vm.isMobile && 'content-button-wrapper-wap'
  }, [_c('div', {
    staticClass: "content-button"
  }, [_c('div', [_c('img', {
    attrs: {
      "src": require('@images/threeStar.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$lang('Stake')) + _vm._s(_vm.$parent.inputValue))]), _c('div', {
    staticClass: "right-icon"
  }, [_c('svg', {
    staticStyle: {
      "transform": "scale(1)"
    },
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "9",
      "height": "8",
      "fill": "none"
    }
  }, [_c('path', {
    attrs: {
      "fill": "#fff",
      "d": "M1 3.5a.5.5 0 0 0 0 1zm7.354.854a.5.5 0 0 0 0-.708L5.172.464a.5.5 0 1 0-.708.708L7.293 4 4.464 6.828a.5.5 0 1 0 .708.708zM1 4.5h7v-1H1z"
    }
  })])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };