/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ChangeLanguageButton.vue?vue&type=template&id=4d8c6ed3&scoped=true"
import script from "./ChangeLanguageButton.vue?vue&type=script&lang=js"
export * from "./ChangeLanguageButton.vue?vue&type=script&lang=js"
import style0 from "./ChangeLanguageButton.vue?vue&type=style&index=0&id=4d8c6ed3&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d8c6ed3",
  null
  
)

export default component.exports