var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "auto-brush-content",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "title"
  }, [_c('div', [_vm._v(_vm._s(_vm.$lang('Autobust')))]), _c('svg', {
    attrs: {
      "viewBox": "0 0 24 24"
    },
    on: {
      "click": function ($event) {
        _vm.popTipShow = true;
      }
    }
  }, [_c('path', {
    attrs: {
      "d": "M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2M12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8"
    }
  })])]), _c('div', {
    staticClass: "swith-wrap",
    class: 'swith-wrap-disabled' && _vm.disableClick
  }, [_c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.checked,
      expression: "checked"
    }],
    attrs: {
      "type": "checkbox",
      "disabled": _vm.disableClick
    },
    domProps: {
      "checked": Array.isArray(_vm.checked) ? _vm._i(_vm.checked, null) > -1 : _vm.checked
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.checked,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.checked = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.checked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.checked = $$c;
        }
      }, _vm.onToogle]
    }
  }), _c('span', {
    staticClass: "slider"
  })])]), _c('pop-prompt-two', {
    attrs: {
      "show": _vm.popTipShow,
      "title": _vm.$lang('Automatic Burn Mechanism'),
      "descs": [_vm.$lang('Once activated, daily release tokens will automatically enter the burn pool, gaining a four times increase in hash rate.')]
    },
    on: {
      "update:show": function ($event) {
        _vm.popTipShow = $event;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };