import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const purchaseVuex = createNamespacedHelpers("private");
import BigNumber from "bignumber.js";
import loadConfig from "@Web3WalletConfig/config.json";
import cookie from "js-cookie";
export default {
  name: "purchase",
  computed: {
    ...walletVuex.mapState(["isMobile", "isMobile", "connect", "address"]),
    ...purchaseVuex.mapState(["nftSellInfo", "isApprove", "isLoading"]),
    getHost() {
      return location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/';
    }
  },
  data() {
    return {
      progress: 0,
      receiver: "",
      password: "",
      cfm_passward: "LKJlkj$skd#fnCU",
      buyNum: 1,
      inviter: "",
      price: 0,
      priceLabel: "0",
      token_enough: true,
      time: {}
    };
  },
  watch: {
    connect: {
      async handler(val) {},
      immediate: true
    },
    isLoading: {
      async handler(newVal, oldVal) {},
      immediate: true,
      deep: true
    },
    receiver: {
      async handler(newVal, oldVal) {
        this.getNftBaseInfo(this.receiver);
      },
      immediate: true,
      deep: true
    },
    nftSellInfo: {
      async handler(newVal, oldVal) {
        this.progress = newVal.progress || 0;
      },
      immediate: true,
      deep: true
    }
  },
  async mounted() {
    const walletConnected = localStorage.getItem("WALLET_CONNECTED");
    if (walletConnected == "true" || walletConnected == true) {
      await this.doConnect(true);
    }
    await this.doGetNftBaseInfo();
    await this.doCalcPrice();
  },
  methods: {
    ...walletVuex.mapActions(["doConnect"]),
    ...purchaseVuex.mapActions(["getNftBaseInfo", "calcPrice", "checkApprove", "doApprove", "doBuy"]),
    async doGetNftBaseInfo() {
      clearTimeout(this.time[100]);
      await this.getNftBaseInfo(this.receiver);
      this.time[100] = setTimeout(async () => {
        await this.doGetNftBaseInfo();
      }, 20000);
    },
    buyNumKeyUp() {
      clearTimeout(this.time[4]);
      this.time[4] = setTimeout(() => {
        this.doCalcPrice();
      }, 200);
    },
    async doCalcPrice() {
      if (Number(this.buyNum) > Number(this.nftSellInfo.mintLimit) - Number(this.nftSellInfo.userMint)) {
        this.buyNum = Number(this.nftSellInfo.mintLimit) - Number(this.nftSellInfo.userMint);
      }
      if (Number(this.buyNum) > Number(this.nftSellInfo.openTotalMint) - Number(this.nftSellInfo.mintTotal)) {
        this.buyNum = Number(this.nftSellInfo.openTotalMint) - Number(this.nftSellInfo.mintTotal);
      }
      if (this.buyNum > 0) {
        await this.calcPrice([this.buyNum, async res => {
          this.price = res[0];
          if (new BigNumber(this.price).gt(this.nftSellInfo.usdtBalance)) {
            this.token_enough = false;
          }
          this.priceLabel = new BigNumber(this.price).div(10 ** loadConfig.usdt_decimals).toFixed(0);
          await this.checkApprove(new BigNumber(this.price).toFixed(0));
        }]);
      } else {
        this.price = 0;
        this.priceLabel = "0";
      }
    },
    onMin() {
      this.buyNum = 1;
      clearTimeout(this.time[3]);
      this.time[3] = setTimeout(() => {
        this.doCalcPrice();
      }, 200);
    },
    onMax() {
      this.buyNum = this.nftSellInfo.mintLimit - this.nftSellInfo.userMint;
      clearTimeout(this.time[0]);
      this.time[0] = setTimeout(() => {
        this.doCalcPrice();
      }, 200);
    },
    onSub() {
      this.buyNum = this.buyNum - 1 > 0 ? this.buyNum - 1 : 1;
      clearTimeout(this.time[1]);
      this.time[1] = setTimeout(() => {
        this.doCalcPrice();
      }, 200);
    },
    onAdd() {
      this.buyNum = this.buyNum + 1 <= this.nftSellInfo.mintLimit - this.nftSellInfo.userMint ? this.buyNum + 1 : this.nftSellInfo.mintLimit - this.nftSellInfo.userMint;
      clearTimeout(this.time[2]);
      this.time[2] = setTimeout(() => {
        this.doCalcPrice();
      }, 200);
    },
    async onApprove() {
      if (this.isLoading) return;
      await this.doApprove([this.price, () => {}]);
    },
    async onBuy() {
      if (this.isLoading) return;
      if (this.password !== this.cfm_passward) {
        this.$toasted.error('Password error');
        return;
      }
      if (!this.receiver) {
        this.$toasted.error('Receiver error');
        return;
      }
      await this.doBuy([this.receiver, this.buyNum, this.inviter, () => {
        this.$toasted.success('Trade successfully');
      }]);
    }
  }
};