var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pri-stake",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "stake-main"
  }, [_c('div', {
    staticClass: "s1"
  }, [_c('div', {
    staticClass: "s1-main"
  }, [_c('div', {
    staticClass: "s1-right"
  }, [_c('div', {
    staticClass: "s1__title"
  }, [_vm._v("Purchase Node")]), _c('div', {
    staticClass: "s1-list"
  }, [_c('div', {
    staticClass: "s1-number"
  }, [_c('div', {
    staticClass: "s1-input"
  }, [_c('div', {
    staticClass: "s1-input__title"
  }, [_vm._v("Receiver:")]), _c('div', {
    staticClass: "s1-input-main"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.receiver,
      expression: "receiver"
    }],
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.receiver
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.receiver = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "s1-input"
  }, [_c('div', {
    staticClass: "s1-input__title"
  }, [_vm._v("Node:")]), _c('div', {
    staticClass: "s1-input-main"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.buyNum,
      expression: "buyNum"
    }],
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.buyNum
    },
    on: {
      "keyup": function ($event) {
        return _vm.buyNumKeyUp();
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.buyNum = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "s1-input"
  }, [_c('div', {
    staticClass: "s1-input__title"
  }, [_vm._v("Inviter:")]), _c('div', {
    staticClass: "s1-input-main"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inviter,
      expression: "inviter"
    }],
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.inviter
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.inviter = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "s1-input"
  }, [_c('div', {
    staticClass: "s1-input__title"
  }, [_vm._v("Password:")]), _c('div', {
    staticClass: "s1-input-main"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.password,
      expression: "password"
    }],
    attrs: {
      "type": "password"
    },
    domProps: {
      "value": _vm.password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.password = $event.target.value;
      }
    }
  })])])]), _c('div', {
    staticClass: "s1-value"
  }, [_c('div', {
    staticClass: "s1-val"
  }, [_c('span', [_vm._v("Total Price: ")]), _vm._v(_vm._s(_vm.priceLabel) + " USDT")]), _c('div', {
    staticClass: "s1-val"
  }, [_c('span', [_vm._v("Can mint: ")]), _vm._v(_vm._s(Number(this.nftSellInfo.mintLimit) - Number(this.nftSellInfo.userMint)))])]), _vm.address ? [Number(_vm.nftSellInfo.openTotalMint) <= Number(_vm.nftSellInfo.mintTotal) ? [_c('div', {
    staticClass: "btn-buy dis"
  }, [_vm._v("Mint End")])] : [!_vm.token_enough ? [_c('div', {
    staticClass: "btn-buy dis"
  }, [_vm._v("USDT balance is insufficient")])] : [_vm.isApprove ? [_c('div', {
    staticClass: "btn-buy",
    on: {
      "click": function ($event) {
        return _vm.onBuy();
      }
    }
  }, [_vm.isLoading ? [_c('svg-icon', {
    attrs: {
      "name": "icon-refresh",
      "spin": ""
    }
  })] : _vm._e(), _vm._v(" Buy ")], 2)] : [_c('div', {
    staticClass: "btn-buy",
    on: {
      "click": function ($event) {
        return _vm.onApprove();
      }
    }
  }, [_vm.isLoading ? [_c('svg-icon', {
    attrs: {
      "name": "icon-refresh",
      "spin": ""
    }
  })] : _vm._e(), _vm._v(" Approve ")], 2)]]]] : [_c('div', {
    staticClass: "btn-buy",
    on: {
      "click": _vm.doConnect
    }
  }, [_vm._v("Connect Wallet")])]], 2)])])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };