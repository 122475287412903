import { createNamespacedHelpers } from "vuex";
import LevelContentItemView from "@components/LevelContentItemView.vue";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  components: {
    LevelContentItemView
  },
  data() {
    return {
      firstLevelOpen: true,
      secondLevelOpen: false,
      thirdLevelOpen: false,
      firstLevelData: [],
      secondLevelData: [],
      thirdLevelData: []
    };
  },
  watch: {
    firstLevelOpen: {
      handler(newVal, oldVal) {
        if (newVal) this.loadData(1);
      },
      deep: true,
      immediate: true
    },
    secondLevelOpen: {
      handler(newVal, oldVal) {
        if (newVal) this.loadData(2);
      },
      deep: true,
      immediate: true
    },
    thirdLevelOpen: {
      handler(newVal, oldVal) {
        if (newVal) this.loadData(3);
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "isMobile", "connect", "address"])
  },
  async mounted() {
    await this.loadData(1);
  },
  methods: {
    async loadData(lv) {
      if (this.address) {
        let rs = await this.$http.post("/referral/list", {
          inviter: this.address,
          lv
        });
        if (rs.code == 0) {
          if (lv == 1) {
            this.firstLevelData = rs.data;
          }
          if (lv == 2) {
            this.secondLevelData = rs.data;
          }
          if (lv == 3) {
            this.thirdLevelData = rs.data;
          }
        }
      }
    }
  }
};