import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import loadConfig from "@Web3WalletConfig/config";
export default {
  name: "popPrompt",
  props: {
    title: {
      type: String,
      default: ""
    },
    showCancelBtn: {
      type: Boolean,
      default: true
    },
    cancelTxt: {
      type: String,
      default: ""
    },
    showConfirmBtn: {
      type: Boolean,
      default: true
    },
    confirmTxt: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...walletVuex.mapState(["isMobile"])
  },
  data() {
    return {};
  },
  methods: {
    cancelFn() {
      this.$emit("cancel");
    },
    confirmFn() {
      this.$emit("confirm");
    }
  }
};