var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "is-close": false,
      "height": "auto",
      "width": _vm.isMobile ? '90vw' : '460px'
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main-compute"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "pop-descs"
  }, [_vm._l(_vm.descs, function (item) {
    return _c('div', {
      staticClass: "pop-desc-item"
    }, [_c('div', {
      staticClass: "pop-desc-item-title"
    }, [_vm._v(_vm._s(item.title))]), _c('div', {
      staticClass: "pop-desc-item-value"
    }, [_vm._v(_vm._s(item.value))])]);
  }), _vm.otherInfo ? _c('div', {
    staticClass: "pop-descs-other-info"
  }, [_vm._v(_vm._s(_vm.otherInfo))]) : _vm._e()], 2), _c('div', {
    staticClass: "button-close",
    on: {
      "click": _vm.cancelFn
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('close')) + " ")])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };