import { createNamespacedHelpers } from "vuex";
import PopPromptTwo from "@components/popPromptTwo.vue";
import BigNumber from "bignumber.js";
import PopPromptCompute from "@components/popPromptCompute.vue";
import loadConfig from "@Web3WalletConfig/config.json";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: 'ComputeView',
  components: {
    PopPromptCompute,
    PopPromptTwo
  },
  props: {},
  data() {
    return {
      showDialogBaseCompute: false,
      showDialogInviteCompute: false,
      showDialogRankingCompute: false
    };
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "isMobile", "connect", "address", "userInfo"]),
    totalSuanLiChi() {
      if (this.userInfo != null) {
        return (Number(this.userInfo?.total_node_hashrate || 0) + Number(this.userInfo?.total_coin_hashrate || 0) + Number(this.userInfo?.total_invite_hashrate || 0) + Number(this.userInfo?.total_kol_hashrate || 0) + Number(this.userInfo?.total_community_hashrate || 0)).toFixed(2);
      }
      return '0.00';
    },
    ownTotalSunLi() {
      if (this.userInfo) {
        return (Number(this.userInfo?.user?.total_node_hashrate || 0) + Number(this.userInfo?.user?.total_coin_hashrate || 0) + Number(this.userInfo?.user?.total_invite_hashrate || 0) + Number(this.userInfo?.user?.total_kol_hashrate || 0) + Number(this.userInfo?.user?.total_community_hashrate || 0)).toFixed(2);
      }
      return '0.00';
    },
    ownTotalSunLiMax() {
      if (this.userInfo) {
        return (Number(this.userInfo?.user?.total_node_hashrate || 0) + Number(this.userInfo?.user?.total_coin_hashrate || 0) + Number(this.userInfo?.user?.total_invite_noburn_hashrate || 0) + Number(this.userInfo?.user?.total_kol_hashrate || 0) + Number(this.userInfo?.user?.total_community_noburn_hashrate || 0)).toFixed(2);
      }
      return '0.00';
    },
    ownNodeSunLi() {
      if (this.userInfo) {
        return Number(this.userInfo?.user?.total_node_hashrate || 0).toFixed(2);
      }
      return '0.00';
    },
    ownCoinSunLi() {
      if (this.userInfo) {
        return Number(this.userInfo?.user?.total_coin_hashrate || 0).toFixed(2);
      }
      return '0.00';
    },
    ownCoinSunLi0() {
      if (this.userInfo && this.userInfo.user && this.userInfo.user.total_coin_stake_info.length > 0) {
        const result = this.userInfo.user.total_coin_stake_info.find(item => item.stake_type == 0);
        if (result) return Number(result.total_hashrate).toFixed(2);
      }
      return '0.00';
    },
    ownCoinSunLi1() {
      if (this.userInfo && this.userInfo.user && this.userInfo.user.total_coin_stake_info.length > 0) {
        const result = this.userInfo.user.total_coin_stake_info.find(item => item.stake_type == 1);
        if (result) return Number(result.total_hashrate).toFixed(2);
      }
      return '0.00';
    },
    ownCoinSunLi2() {
      if (this.userInfo && this.userInfo.user && this.userInfo.user.total_coin_stake_info.length > 0) {
        const result = this.userInfo.user.total_coin_stake_info.find(item => item.stake_type == 2);
        if (result) return Number(result.total_hashrate).toFixed(2);
      }
      return '0.00';
    },
    ownBaseSunLi() {
      if (this.userInfo) {
        return (Number(this.userInfo?.user?.total_node_hashrate || 0) + Number(this.userInfo?.user?.total_coin_hashrate || 0)).toFixed(2);
      }
      return '0.00';
    },
    ownBaseSunLiMax() {
      if (this.userInfo) {
        return (Number(this.userInfo?.user?.total_node_hashrate || 0) + Number(this.userInfo?.user?.total_coin_hashrate || 0)).toFixed(2);
      }
      return '0.00';
    },
    ownInviteSunLi() {
      if (this.userInfo) {
        return (Number(this.userInfo?.user?.total_invite_hashrate || 0) + Number(this.userInfo?.user?.total_kol_hashrate || 0)).toFixed(2);
      }
      return '0.00';
    },
    directInviteSunLi() {
      if (this.userInfo) {
        return Number(this.userInfo?.user?.direct_total_invite_hashrate || 0).toFixed(2);
      }
      return '0.00';
    },
    indirectlyInviteSunLi() {
      if (this.userInfo) {
        return (Number(this.userInfo?.user?.total_invite_hashrate || 0) - Number(this.userInfo?.user?.direct_total_invite_hashrate || 0)).toFixed(2);
      }
      return '0.00';
    },
    ownRankingSunLi() {
      if (this.userInfo) {
        return Number(this.userInfo?.user?.total_community_hashrate || 0).toFixed(2);
      }
      return '0.00';
    },
    ownInviteSunLiMax() {
      if (this.userInfo) {
        return (Number(this.userInfo?.user?.total_invite_noburn_hashrate || 0) + Number(this.userInfo?.user?.total_kol_hashrate || 0)).toFixed(2);
      }
      return '0.00';
    },
    ownRankingSunLiMax() {
      if (this.userInfo) {
        return Number(this.userInfo?.user?.total_community_noburn_hashrate || 0).toFixed(2);
      }
      return '0.00';
    },
    ownRemainRankingSunli() {
      if (this.userInfo) {
        return ((Number(this.userInfo?.user?.total_node_hashrate || 0) + Number(this.userInfo?.user?.total_coin_hashrate || 0)) * 10 - Number(this.userInfo?.user?.total_community_hashrate || 0)).toFixed(2);
      }
      return '0.00';
    }
  }
};