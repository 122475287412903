import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "popPromptTwo",
  props: {
    title: {
      type: String,
      default: ""
    },
    descs: {
      type: Array,
      default: []
    }
  },
  computed: {
    ...walletVuex.mapState(["isMobile"])
  },
  data() {
    return {};
  },
  methods: {
    cancelFn() {
      this.$emit("cancel");
    },
    confirmFn() {
      this.$emit("confirm");
    }
  }
};