import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...walletVuex.mapState(["isMobile"])
  }
};