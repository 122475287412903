var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "height": "auto",
      "width": _vm.isMobile ? '90vw' : '460px'
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "pop-descs"
  }, _vm._l(_vm.descs, function (item) {
    return _c('div', {
      staticClass: "pop-desc-item"
    }, [_vm._v(_vm._s(item))]);
  }), 0)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };