export default {
  name: "popDialog",
  data() {
    return {
      hide: []
    };
  },
  props: {
    list: {
      type: [Array, Object],
      default: []
    },
    pindex: {
      type: Number,
      default: 0
    },
    isHidden: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onHide(id, bool) {
      this.$set(this.hide, id, bool);
    }
  }
};