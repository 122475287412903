import { langDefault, langList } from '@i18n/i18nOption.json';
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers('wallet');
export default {
  inject: ["reload"],
  computed: {
    lang() {
      return langList;
    },
    ...walletVuex.mapState(['isMobile'])
  },
  data() {
    return {
      isShow: false,
      selectLangValue: '',
      selectLang: ''
    };
  },
  watch: {
    selectLangValue: {
      handler(val) {
        if (val == '') {
          this.setLangFn();
        }
      },
      immediate: true
    }
  },
  methods: {
    ...walletVuex.mapMutations({
      setLang: 'SETLANG'
    }),
    setLangFn() {
      const filterLang = val => {
        return this.lang.filter(item => item.value.includes(val))[0];
      };
      if (localStorage.getItem('lang')) {
        const langObj = filterLang(localStorage.getItem('lang'));
        this.selectLang = langObj.label;
        this.selectLangValue = langObj.text;
        this.flag = langObj.flag;
        this.setLang(langObj.value);
      } else {
        const langObj = filterLang(langDefault);
        this.selectLang = langObj.label;
        this.flag = langObj.flag;
        localStorage.setItem('lang', langObj.value);
        this.setLang(langObj.value);
      }
    },
    changeLanguage(row) {
      if (row.label == this.selectLang) return;
      this.selectLang = row.label;
      this.selectLangValue = row.text;
      this.flag = row.flag;
      this.$i18n.locale = row.value;
      localStorage.setItem('lang', row.value);
      this.setLang(row.value);
      this.reload();
    }
  }
};