var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "indexPage",
    class: _vm.isMobile && 'wap'
  }, [_c('div', {
    staticClass: "index-main"
  }, [_c('div', {
    staticClass: "p1"
  }, [_c('div', {
    staticClass: "p1-main"
  }, [_vm._m(0), _vm._m(1), _c('div', {
    staticClass: "p1-intro-bot wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "1s"
    }
  }, [_vm._m(2), _c('div', {
    staticClass: "btn-1"
  }, [_vm._v(" AI Digital Integration "), _c('div', {
    staticClass: "icon"
  }, [_c('svg-icon', {
    attrs: {
      "name": "icon-arrow2"
    }
  })], 1)]), _c('div', {
    staticClass: "btn-2"
  }, [_vm._v("AI Simulation Training")])]), _c('div', {
    staticClass: "p1-intro"
  }, [_c('div', {
    staticClass: "p1-intro__title"
  }, [_c('vue-typed-js', {
    attrs: {
      "typeSpeed": 30,
      "showCursor": false,
      "strings": [_vm.$lang('WHAT IS REFLECTION?')]
    }
  }, [_c('span', {
    staticClass: "typing"
  })])], 1), _c('div', {
    staticClass: "p1-intro__main wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.4s"
    }
  }, [!_vm.isMobile ? _c('div', {
    staticClass: "p1-img img1"
  }, [_c('img', {
    staticClass: "wow animate__animated animate__bounceIn",
    attrs: {
      "data-wow-delay": "0.4s",
      "src": require('@images/p1-img-1.png'),
      "alt": ""
    }
  })]) : _vm._e(), _vm._m(3), _vm.isMobile ? _c('div', {
    staticClass: "p1-img img1"
  }, [_c('img', {
    staticClass: "wow animate__animated animate__bounceIn",
    attrs: {
      "data-wow-delay": "0.4s",
      "src": require('@images/p1-img-1.png'),
      "alt": ""
    }
  })]) : _vm._e()])])])]), _c('div', {
    staticClass: "p2"
  }, [_c('div', {
    staticClass: "p2-main"
  }, [_c('div', {
    staticClass: "p2-con c1"
  }, [_vm._m(4), _c('div', {
    staticClass: "p2-img img1"
  }, [_c('img', {
    staticClass: "wow animate__animated animate__bounceIn",
    attrs: {
      "data-wow-delay": "0.4s",
      "src": require('@images/p2-img-2.png'),
      "alt": ""
    }
  })])]), _c('div', {
    staticClass: "p2-con pv c2"
  }, [!_vm.isMobile ? _c('div', {
    staticClass: "icons wow animate__animated animate__fadeInUp"
  }, [_c('div', {
    staticClass: "icon a"
  }, [_c('img', {
    attrs: {
      "src": require('@images/p2-cv2-icon-1.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require('@images/p2-cv2-icon-2.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "icon a"
  }, [_c('img', {
    attrs: {
      "src": require('@images/p2-cv2-icon-3.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require('@images/p2-cv2-icon-4.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "icon a"
  }, [_c('img', {
    attrs: {
      "src": require('@images/p2-cv2-icon-5.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require('@images/p2-cv2-icon-6.png'),
      "alt": ""
    }
  })])]) : _vm._e(), _vm._m(5), _vm.isMobile ? _c('div', {
    staticClass: "icons wow animate__animated animate__fadeInUp"
  }, [_c('div', {
    staticClass: "icon a"
  }, [_c('img', {
    attrs: {
      "src": require('@images/p2-cv2-icon-1.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require('@images/p2-cv2-icon-2.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "icon a"
  }, [_c('img', {
    attrs: {
      "src": require('@images/p2-cv2-icon-3.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require('@images/p2-cv2-icon-4.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "icon a"
  }, [_c('img', {
    attrs: {
      "src": require('@images/p2-cv2-icon-5.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require('@images/p2-cv2-icon-6.png'),
      "alt": ""
    }
  })])]) : _vm._e()]), _c('div', {
    staticClass: "p2-con c3"
  }, [_vm._m(6), _c('div', {
    staticClass: "p2-img"
  }, [!_vm.isMobile ? _c('img', {
    staticClass: "wow animate__animated animate__bounceIn",
    attrs: {
      "data-wow-delay": "0.4s",
      "src": require('@images/p2-img-3.png'),
      "alt": ""
    }
  }) : _c('img', {
    staticClass: "wow animate__animated animate__bounceIn",
    attrs: {
      "data-wow-delay": "0.4s",
      "src": require('@images/wap/p2-img-3.png'),
      "alt": ""
    }
  })])]), _c('div', {
    staticClass: "p2-con c4"
  }, [!_vm.isMobile ? _c('div', {
    staticClass: "p2-img img4"
  }, [_c('img', {
    staticClass: "wow animate__animated animate__bounceIn",
    attrs: {
      "data-wow-delay": "0.4s",
      "src": require('@images/p2-img-4.png'),
      "alt": ""
    }
  })]) : _vm._e(), _vm._m(7), _vm.isMobile ? _c('div', {
    staticClass: "p2-img img4"
  }, [_c('img', {
    staticClass: "wow animate__animated animate__bounceIn",
    attrs: {
      "data-wow-delay": "0.4s",
      "src": require('@images/p2-img-4.png'),
      "alt": ""
    }
  })]) : _vm._e()])])]), _c('div', {
    staticClass: "p3"
  }, [_c('div', {
    staticClass: "p3-main"
  }, [_c('div', {
    staticClass: "p3-list"
  }, [_c('div', {
    staticClass: "p3-item"
  }, [_c('div', {
    staticClass: "p3-item__head"
  }, [_c('img', {
    attrs: {
      "src": require('@images/icon-vector.png'),
      "alt": ""
    }
  })]), _vm._m(8), _c('div', {
    staticClass: "p3-item__label wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "1s"
    }
  }, [_vm._v("With over 6K A100 computing cards, our platform offers robust hardware support, ensuring efficient and reliable services for creating and nurturing virtual AI personalities.")])]), _c('div', {
    staticClass: "p3-item"
  }, [_c('div', {
    staticClass: "p3-item__head"
  }, [_c('img', {
    attrs: {
      "src": require('@images/icon-vector1.png'),
      "alt": ""
    }
  })]), _vm._m(9), _c('div', {
    staticClass: "p3-item__label wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "1.2s"
    }
  }, [_vm._v("Reflection aims to attract over 100M users, offering abundant social space and growth opportunities for developing virtual AI personalities in a lively and dynamic environment.")])]), _c('div', {
    staticClass: "p3-item"
  }, [_c('div', {
    staticClass: "p3-item__head"
  }, [_c('img', {
    attrs: {
      "src": require('@images/icon-vector2.png'),
      "alt": ""
    }
  })]), _vm._m(10), _c('div', {
    staticClass: "p3-item__label wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "1.4s"
    }
  }, [_vm._v("Reflection leverages cutting-edge AI to enable tailored virtual identities that learn and adapt through interaction with the user, offering an evolving experience that mirrors the users’ unique personality.")])])])])]), _vm._m(11), _c('div', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('img', {
    attrs: {
      "src": require('@images/footer-logo.png'),
      "alt": ""
    }
  })])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "p1-title wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.2s"
    }
  }, [_vm._v(" Decentralized"), _c('br'), _c('i', [_vm._v("AI virtual personality")]), _vm._v(" "), _c('br'), _vm._v(" and "), _c('i', [_vm._v("Social")]), _vm._v(" Computing Platform ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "btn-started wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.8s"
    }
  }, [_c('div', {
    staticClass: "txt"
  }, [_vm._v("GET START")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "btn-0"
  }, [_vm._v(" AI Digital "), _c('br'), _vm._v(" Personality ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "p1-info"
  }, [_c('div', {
    staticClass: "p1-info__label wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.4s"
    }
  }, [_vm._v(" Reflection aims to be the pioneering Web3 platform for virtual personalities, going beyond traditional digital avatars and identities. ")]), _c('div', {
    staticClass: "p1-info__label wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.6s"
    }
  }, [_vm._v(" Users can create a virtual AI-powered replica of themselves that communicates, learns, and evolves autonomously. Over time, this AI clone may surpass the original user in capabilities while always retaining their unique personality and traits. ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "p2-info"
  }, [_c('div', {
    staticClass: "p2-info__title wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.2s"
    }
  }, [_c('i', [_vm._v("Dec")]), _vm._v("en"), _c('i', [_vm._v("tralized")]), _vm._v(" "), _c('br'), _c('i', [_vm._v("com")]), _vm._v("pu"), _c('i', [_vm._v("ting p")]), _vm._v("ower")]), _c('div', {
    staticClass: "p2-info__label wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.4s"
    }
  }, [_vm._v("By leveraging the computing power of global users and devices, we create an extensive network with powerful reasoning and rendering capabilities. This decentralized approach reduces computing costs and increases efficiency, ensuring quick responses to user demands.")]), _c('div', {
    staticClass: "btn-more wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.6s"
    }
  }, [_vm._v("LEARN MORE")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "p2-info"
  }, [_c('div', {
    staticClass: "p2-info__title wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.2s"
    }
  }, [_c('i', [_vm._v("Virtu")]), _vm._v("al AI "), _c('br'), _c('i', [_vm._v("pers")]), _vm._v("on"), _c('i', [_vm._v("ality Sy")]), _vm._v("stem")]), _c('div', {
    staticClass: "p2-info__label wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.4s"
    }
  }, [_vm._v("Users can create a personalized virtual AI personality by uploading photos, voice samples, and personal information. Through text and voice interactions, the users’ AI personality adapts to mirror their traits and habits, providing a unique virtual companion for leisure and support.")]), _c('div', {
    staticClass: "btn-more wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.6s"
    }
  }, [_vm._v("LEARN MORE")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "p2-info"
  }, [_c('div', {
    staticClass: "p2-info__title wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.2s"
    }
  }, [_c('i', [_vm._v("Virt")]), _vm._v("ual "), _c('i', [_vm._v("AI  ")]), _vm._v(" "), _c('br'), _c('i', [_vm._v("sim")]), _vm._v("ul"), _c('i', [_vm._v("ation w")]), _vm._v("orld")]), _c('div', {
    staticClass: "p2-info__label wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.4s"
    }
  }, [_vm._v("By leveraging a global network of users and devices, we create a powerful computing infrastructure for advanced reasoning and rendering. This decentralized approach reduces costs and improves efficiency, ensuring quick responses to users’ needs.")]), _c('div', {
    staticClass: "btn-more wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.6s"
    }
  }, [_vm._v("LEARN MORE")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "p2-info"
  }, [_c('div', {
    staticClass: "p2-info__title wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.2s"
    }
  }, [_c('i', [_vm._v("Com")]), _vm._v("pu"), _c('i', [_vm._v("ting  ")]), _c('br'), _c('i', [_vm._v("Pow")]), _vm._v("er "), _c('i', [_vm._v("as Tok")]), _vm._v("ens")]), _c('div', {
    staticClass: "p2-info__label wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.4s"
    }
  }, [_vm._v("Our platform allows both professional providers and regular users to contribute computing power and conduct model training. In return, users earn tokens, which can be used for platform services or converted into additional revenue. This incentive model attracts more participants, creating a vast and dynamic computing ecosystem.")]), _c('div', {
    staticClass: "btn-more wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.6s"
    }
  }, [_vm._v("LEARN MORE")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "p3-item__title wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.2s"
    }
  }, [_vm._v("Robust"), _c('br'), _vm._v("Computing"), _c('br'), _vm._v("Power")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "p3-item__title wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.4s"
    }
  }, [_vm._v("Extensive"), _c('br'), _vm._v(" User Base")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "p3-item__title wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.6s"
    }
  }, [_vm._v("Advanced AI"), _c('br'), _vm._v("Technologies")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "p4"
  }, [_c('div', {
    staticClass: "p4-main"
  }, [_c('div', {
    staticClass: "p4-title"
  }, [_vm._v("ROAD MAP")]), _c('div', {
    staticClass: "p4-main-box"
  }, [_c('div', {
    staticClass: "p4-list p4-list-1"
  }, [_c('div', {
    staticClass: "p4-item wow animate__animated animate__fadeInDown",
    attrs: {
      "data-wow-delay": "0.2s"
    }
  }, [_c('div', {
    staticClass: "p4-item__time"
  }, [_vm._v("Q2 2024")]), _c('div', {
    staticClass: "p4-item__title"
  }, [_vm._v(" Technical Development"), _c('br'), _vm._v(" and Prototype Testing ")]), _c('div', {
    staticClass: "p4-item__label"
  }, [_c('p', [_vm._v("Develop core technical architecture, including the social module and AI language model.")]), _c('p', [_vm._v("Recruit and train early users and computing power nodes.")]), _c('p', [_vm._v("Conduct prototype testing, gather feedback, and optimize.")])])]), _c('div', {
    staticClass: "p4-item wow animate__animated animate__fadeInDown",
    attrs: {
      "data-wow-delay": "0.6s"
    }
  }, [_c('div', {
    staticClass: "p4-item__time"
  }, [_vm._v("Q4 2024")]), _c('div', {
    staticClass: "p4-item__title"
  }, [_vm._v(" Beta Testing and "), _c('br'), _vm._v(" Launch Preparation ")]), _c('div', {
    staticClass: "p4-item__label"
  }, [_c('p', [_vm._v("Roll out Beta version and onboard additional testers.")]), _c('p', [_vm._v("Perform comprehensive feature testing and enhancements.")]), _c('p', [_vm._v("Prepare for official launch with marketing strategies and user training materials.")])])]), _c('div', {
    staticClass: "p4-item wow animate__animated animate__fadeInDown",
    attrs: {
      "data-wow-delay": "1s"
    }
  }, [_c('div', {
    staticClass: "p4-item__time"
  }, [_vm._v("Q2 2025")]), _c('div', {
    staticClass: "p4-item__title"
  }, [_vm._v(" Continued Growth"), _c('br'), _vm._v(" and Innovation ")]), _c('div', {
    staticClass: "p4-item__label"
  }, [_c('p', [_vm._v("Continuously refine the platform based on user feedback and market trends.")]), _c('p', [_vm._v("Introduce new features like the AI personality system and AI virtual world.")]), _c('p', [_vm._v("Expand the ecosystem, strengthen partnerships, and build a vibrant community.")])])])]), _c('div', {
    staticClass: "p4-list p4-list-2"
  }, [_c('div', {
    staticClass: "p4-item wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.4s"
    }
  }, [_c('div', {
    staticClass: "p4-item__time"
  }, [_vm._v("Q3 2024")]), _c('div', {
    staticClass: "p4-item__title"
  }, [_vm._v(" Alpha Testing and"), _c('br'), _vm._v(" Feature Enhancement ")]), _c('div', {
    staticClass: "p4-item__label"
  }, [_c('p', [_vm._v("Launch Alpha version for selected users to test AI personalities and social features.")]), _c('p', [_vm._v("Expand and evaluate new computing power nodes.")]), _c('p', [_vm._v("Refine features based on tester feedback for optimal user experience.")])])]), _c('div', {
    staticClass: "p4-item wow animate__animated animate__fadeInUp",
    attrs: {
      "data-wow-delay": "0.8s"
    }
  }, [_c('div', {
    staticClass: "p4-item__time"
  }, [_vm._v("Q1 2025")]), _c('div', {
    staticClass: "p4-item__title"
  }, [_vm._v(" Official Launch and Ecosystem"), _c('br'), _vm._v(" Development ")]), _c('div', {
    staticClass: "p4-item__label"
  }, [_c('p', [_vm._v("Public launch of the Reflection platform with open registration.")]), _c('p', [_vm._v("Expand network of computing power nodes with new recruits.")]), _c('p', [_vm._v("Strengthen the ecosystem to attract users and developers, fostering growth and innovation.")])])])])])])]);
}];
export { render, staticRenderFns };