import loadConfig from "@Web3WalletConfig/config.json";
import BigNumber from "bignumber.js";
import Vue from "vue";
import {ethers, keccak256 , toUtf8Bytes,Interface } from 'ethers'
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi1";
import { fetchFeeData, watchNetwork, watchAccount, prepareWriteContract, waitForTransaction, writeContract, readContract, disconnect, switchNetwork, getNetwork, getAccount, signMessage, sendTransaction, prepareSendTransaction, fetchBalance } from "@wagmi/core";

import * as chainsList from "viem/chains";
var Web3 = require("web3");

var status = {
  SUCCESS: 0,
  DISABLE_CONNECT: 1, //用户取消钱包连接
  WALLET_NULL: 2, //钱包没有安装
  ACCOUNT_NULL: 3, //钱包锁住
  NET_ID_ERROR: 4, //无法获得网络ID
  SIGN_ERROR: 5, //签名失败
  SWITCH_WALLET_ERROR: 6, //切换网络ID失败
  PROVIDER_Error: 7, //provider接入失败
};

var Web3Wallet = {
  connected: false, //是否已经连接上钱包 Boolean
  web3: null, //Object webjs api
  address: null, //钱包地址 Sring
  netId: 0, //网络ID Number
  netInfo: null, //网络基本信息对象 包含网络id 钱包地址 精度等等 Object
  netIdError: false, //网络id 与配置中的net_id是否匹配的状态 Boolean true: 不匹配 false 匹配
  modal: null, //模态框对象 Object
  isModal: false, //web3Modal 引导UI状态 显示隐藏状态 Boolean true：显示 false：隐藏
  web3Provider: null, //接入的provider Proxy
  connectTimer: null, //监听web3Modal引导UI定时器 setting
  unWatchAccount: null, //取消订阅账户更改事件函数 function
  unWatchNetwork: null, //取消订阅网络更改事件函数 function
  //初始化web3模态框
  initWeb3Modal: function () {
    const projectId = loadConfig.web3ModalProjectId;
    const featuredWalletIds = ["ad2eff108bf828a39e5cb41331d95861c9cc516aede9cb6a95d75d98c206e204", "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96", "8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4" , "971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709","c7708575a2c3c9e6a8ab493d56cdcc56748f03956051d021b8cd8d697d9a3fd2", "20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66"];
    const web3ModalConfig = loadConfig.web3ModalConfig;
    const chains = [chainsList[web3ModalConfig.chains]];
    const metadata = web3ModalConfig.metadata;
    console.log("chains",chains);
    const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });
    console.log("wagmiConfig",wagmiConfig)
    //创建模态框
    const modal = createWeb3Modal({ featuredWalletIds, wagmiConfig, projectId, chains });
    Web3Wallet.modal = modal;
    modal.getEvent();
    //订阅模态框
    modal.subscribeEvents(async (event) => {
      const { open } = modal.getState();
      const { isConnected } = getAccount();
      localStorage.setItem("WALLET_CONNECTED", Boolean(isConnected)); //缓存钱包是否连接过的状态
      console.log("订阅模态框", Boolean(isConnected));
      //链接钱包后才继续执行后续流程
      if (isConnected) {
        Web3Wallet.isModal = true;
      }

      if (!Web3Wallet.web3Provider) {
        //接入provider 已连接钱包时 直接跳过web3Modal引导页
        Web3Wallet.web3Provider = Web3Wallet.getProvider();
        Web3Wallet.web3 = new Web3(Web3Wallet.web3Provider);
        console.log("Web3Wallet.web3",Web3Wallet.web3);
      }
    });
  },

  //实时查询网络ID
  checkNetId: function () {
    return new Promise(async (resolve, reject) => {
      try {
        const network = getNetwork();
        const { chain, chains } = network;
        console.log("checkNetId chain info", chain, chains);
        if (chain) {
          const netId = chain.id;
          Web3Wallet.netInfo = chain;
          console.log("Web3Wallet.netInfo", Web3Wallet.netInfo);
          if (netId == loadConfig.net_id) {
            Web3Wallet.netId = netId;
            Web3Wallet.netIdError = false;
            console.log("checkNetId success");
            resolve(status.SUCCESS);
          } else {
            Web3Wallet.netId = 0;
            Web3Wallet.netIdError = true;
            await Web3Wallet.switchWallet();
            reject(status.NET_ID_ERROR);
          }
        } else {
          Web3Wallet.netId = 0;
          Web3Wallet.netIdError = true;
          console.log("checkNetId failed chains && chains.length>0");
          resolve(status.SUCCESS);
        }
      } catch (err) {
        console.error("checkNetId Error: ", err);
        reject();
      }
    });
  },

  //发起交易
  sendTransaction: async function (data) {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          chainId: loadConfig.net_id,
          account: data?.from ? data.from : data?.account ? data.acount : null,
          to: data.to,
          value: String(data.value), //发送的 wei 值
          data: data?.data,
        };
        const request = await prepareSendTransaction(params);
        console.log("sendTransaction params", request);
        const { hash } = await sendTransaction({ chainId: loadConfig.net_id, ...request });
        console.log("sendTransaction hash", hash);
        const result = await waitForTransaction({ chainId: loadConfig.net_id, hash });
        console.log("sendTransaction result", result);
        resolve(result);
      } catch (err) {
        reject(err);
        console.log("sendTransation error:", err);
      }
    });
  },
  //获取provier
  getProvider: function () {
    return new Promise((resolve, reject) => {
      let providerRow = null;
      //web3Modal提供的eip6963的provider
      // const getConnectors = Web3Wallet.modal.getConnectors();
      // console.log("getConnectors", getConnectors);
      // for (const iterator of getConnectors) {
      //   if (iterator.id == "eip6963") {
      //     providerRow = iterator.provider;
      //   }
      // }
      
      providerRow = new ethers.JsonRpcProvider(loadConfig.rpc)
      console.log("成功接入provider---eip6963:", providerRow);
      resolve(providerRow);
    });
  },
  //实时更新钱包及账户信息
  checkAccount: async function () {
    return new Promise(async (resolve, reject) => {
      try {
        await Web3Wallet.checkNetId();
        const account = getAccount();
        const { address, isConnected } = account;
        console.log("checkAccount info: ", address, isConnected, account);
        localStorage.setItem("WALLET_CONNECTED", Boolean(isConnected));
        if (Boolean(isConnected) && !Web3Wallet.netIdError) {
          Web3Wallet.address = address;
          Web3Wallet.connected = Boolean(isConnected);
          console.log("checkAccount success");
          resolve(status.SUCCESS);
        } else {
          console.log("errcheckAccount Error: ", isConnected, Web3Wallet.netIdError, account);
          Web3Wallet.disConnect();
          reject(status.WALLET_NULL);
        }
      } catch (err) {
        console.log("errcheckAccount Error1: ", err);
        Web3Wallet.disConnect();
        reject(status.WALLET_NULL);
      }
    });
  },
  //签名操作
  signMessage: async function (sign) {
    return new Promise(async (resolve, reject) => {
      try {
        console.log("signMessage sign info:", sign);
        const signature = await signMessage({
          message: sign,
        });
        console.log("signature success");
        resolve(signature);
      } catch (err) {
        Vue.prototype.$toasted.error(Vue.prototype.$lang("signMessage Error!"));
        console.log("signMessage err", err);
        reject(status.SIGN_ERROR);
      }
    });
  },

  //钱包连接
  connect: function () {
    return new Promise(async (resolve, reject) => {
      try {
        console.log("window.ethereum", Web3Wallet);
        console.log("Web3Wallet.connect", Web3Wallet, Web3Wallet.connected);
        if (Web3Wallet.unWatchAccount !== null) {
          Web3Wallet.unWatchAccount();
        }
        if (Web3Wallet.unWatchNetwork !== null) {
          Web3Wallet.unWatchNetwork();
        }
        let isWeb3Modal = null; //是否要显示web3Modal
        const store = require("@store/index");
        const isMobile = store.default.state.wallet.isMobile; //是否是手机端
        //检测是否链接钱包
        Web3Wallet.web3Provider = await Web3Wallet.getProvider();
        if (Web3Wallet.web3Provider) {
          Web3Wallet.web3 = new Web3(Web3Wallet.web3Provider);
          isWeb3Modal = true;
        } else {
          //没有provider
          isWeb3Modal = false;
        }

        console.log("isMobile*******", isMobile);
        const { isConnected } = getAccount();
        if (!Web3Wallet.connected && !isConnected) {
          Web3Wallet.modal.open();
          Web3Wallet.connectTimer = null;
          Web3Wallet.connectTimer = setInterval(async () => {
            console.log("22222", Web3Wallet.isModal);
            if (Web3Wallet.isModal) {
              console.log(3333);
              clearInterval(Web3Wallet.connectTimer);
              Web3Wallet.connectTimer = null;
              const status = await Web3Wallet.checkAccount();
              Web3Wallet.modal.close();
              console.log("connectTimer", status);
              resolve(status.SUCCESS);
            }
          }, 1000);
        } else {
          try {
            const checkAccount = await Web3Wallet.checkAccount();
            console.log("*******xxxxxxxxx", checkAccount);
            resolve(status.SUCCESS);
          } catch (err) {
            console.log("connect Error:", err);
            reject(status.WALLET_NULL);
          }
        }
      } catch (err) {
        reject(status.WALLET_NULL);
        console.log("connect Error1:", err);
      }
    });
  },
  //切换钱包地址
  switchWallet: async function () {
    return new Promise(async (resolve, reject) => {
      try {
        console.log("********正在切换网络", Web3Wallet);
        const chain = await switchNetwork({
          chainId: loadConfig.net_id,
        });

        console.log("********网络切换成功", Web3Wallet, chain);
        console.log("switchWallet info", chain);
        console.log("switchWallet Success");
        //检测是否切换成功
        await Web3Wallet.checkNetId();
        if (!Web3Wallet.netIdError) {
          Vue.prototype.$toasted.error(Vue.prototype.$lang("Switch successful. Please reconnect to the network."));
          resolve(status.SUCCESS);
        } else {
          console.log("switchWallet Error", err);
          reject(status.SWITCH_WALLET_ERROR);
        }
      } catch (err) {
        // Web3Wallet.netIdError && Vue.prototype.$toasted.error(Vue.prototype.$lang("Net Id Error!"));
        Web3Wallet.disConnect();
        console.log("switchWallet Error", err);
        reject(status.SWITCH_WALLET_ERROR);
      }
    });
  },
  //退出钱包
  disConnect: async function () {
    try {
      console.log("Web3Wallet", Web3Wallet);
      Web3Wallet.address = null;
      Web3Wallet.connected = false;
      Web3Wallet.web3Provider = null;
      Web3Wallet.isModal = false;
      localStorage.removeItem("WALLET_CONNECTED");
      console.log("disConnect success");
      disconnect();
      Web3Wallet.unWatchAccount();
    } catch (e) {}
  },
  //查询余额wei Promise
  getBalance: async function (_address) {
    return new Promise(async (resolve, reject) => {
      try {
        const balance = await fetchBalance({
          address: _address,
          chainId: loadConfig.net_id,
        });
        console.log("getBalance info:", balance);
        resolve(balance);
      } catch (err) {
        console.log("getBalance err:", err);
        resolve({
          value: 0,
          label: 0,
        });
      }
    });
  },
  //生成合约
  contract: function (name, address = null) {
    console.log("contract", name, address);
    var data, abi , fee; // 手续费 邮费
    if (process.env.VUE_APP_MODE === "dev-prod" || process.env.VUE_APP_MODE === "prod") {
      data = require("./contracts/" + name + ".json");
      // fee = "5";
    } else {
      data = require("./contracts_for_test/" + name + ".json");
      // fee = "20";
    }
    if (!address) {
      address = data.address;
    }

    abi =  data.abi;

    let contractInstant = {
      send: null,
      call: null,
    };
    
    contractInstant.send = function (functionName, params = [], value = 0) {
      console.log("functionName, params = [], value = 0", functionName, params, value);
      return new Promise(async (resolve, reject) => {
        try {
          const feedData = await fetchFeeData();
          console.log("feedData", feedData);
          const _params = {
            chainId: loadConfig.net_id,
            address,
            abi,
            functionName,
            args: params,
            value: value > 0 ? value : 0,
            //gasPrice: feedData.gasPrice,
          };
          console.log("contract send params", _params);
          const { request } = await prepareWriteContract(_params);
          console.log("contract send request: ", request);
          const { hash } = await writeContract({ chainId: loadConfig.net_id, ...request });
          console.log("contract send hash: ", hash);
          let result = await waitForTransaction({ chainId: loadConfig.net_id, hash });
          console.log("contract send hash: ", result);
          console.log("contract send success: ", result);

          result['events'] = {};
          abi.map(item=>{
             if(item.type == 'event'){
                let eventName = item.name
                let eventStr =  eventName + "(";
                item.inputs.map(sitem=>{
                  eventStr += sitem.type + ","
                })
                eventStr = eventStr.substring(0,eventStr.length - 1) + ")"
                result.logs.map(sitem=>{
                   if(sitem.topics[0] == keccak256(toUtf8Bytes(eventStr))){
                      try{
                        let logsParse = (new Interface(abi)).parseLog(sitem)
                        let args = {};
                        item.inputs.map((ssitem,index)=>{
                          if(ssitem.name){
                            if(['uint','uint8','uint16','uint128','uint256'].includes(ssitem.internalType))
                              args[ssitem.name] = new BigNumber(logsParse['args'][index]).toFixed(0)
                            else
                              args[ssitem.name] = logsParse['args'][index]
                          }else{
                            args[index] = logsParse['args'][index]
                          }
                        })
                        
                        result['events'][eventName] = args
                      }
                      catch(ex){
                        console.log("---e---",ex);
                      }
                   }
                })
             }
          });

          resolve(result);
        } catch (err) {
          console.log(typeof err, err["Details"]);
          try {
            for (const key in err) {
              if (key == "details") {
                Vue.prototype.$toasted.error(err[key]);
              }
            }
          } catch (err) {}

          console.log("contractInstant send Error: ", err);
          reject(err);
        }
      });
    };
    contractInstant.call = function (functionName, params = []) {
      console.log("contract", functionName, params);
      return new Promise(async (resolve, reject) => {
        try {
          const config = {
            chainId: loadConfig.net_id,
            address,
            abi,
            functionName,
            account: Web3Wallet.address,
            args: params,
          }

          let result = await readContract(config);
          abi.map(item=>{
            if(item.name == functionName){
              item.outputs.map((sitem,index)=>{
                if(sitem.name){
                  if(['uint','uint8','uint16','uint128','uint256'].includes(sitem.internalType))
                    if(typeof result == 'object')
                      result[sitem.name] = new BigNumber(result[index]).toFixed(0)
                    else
                      result = new BigNumber(result).toFixed(0)
                  else if(typeof result == 'object'){
                      result[sitem.name] = result[index]
                  }
                }else{
                  if(['uint','uint8','uint16','uint128','uint256'].includes(sitem.internalType)){
                    if(typeof result == 'object')
                      result[index] = new BigNumber(result[index]).toFixed(0)
                    else
                      result = new BigNumber(result).toFixed(0)
                  }
                }
              })
            }
          })
         
          console.log("contractInstant call success: ", result);
          resolve(result);
        } catch (err) {
          console.log("contractInstant call Error: ", functionName, err);

          reject(err);
        }
      });
    };
    return contractInstant;
  },
  //订阅帐户更改的操作 当连接的帐户发生变化时，将调用回调
  watchAccountChange: function (callback) {
    Web3Wallet.unWatchAccount = watchAccount(async (account) => {
      console.log("****watchAccountChange success", account);
      Web3Wallet.address = account.address;
      Web3Wallet.connected = account.connected;
      typeof callback == "function" && (await callback(account));
    });
    console.log("Web3Wallet.unWatchAccount", Web3Wallet.unWatchAccount);
  },
  watchNetworkChange: function (callback) {
    Web3Wallet.unWatchNetwork = watchNetwork(async (network) => {
      console.log("****unWatchNetwork success", network);
      // bug 当连接状态下发生网络变化时 watchNetwork自动切换网络时 Metamask会不可预测断开 所以暂时不使用checkNetId 直接改变login状态重新连接
      const { chain, chains } = network;
      console.log("checkNetId chain info", chain, chains);
      if (chain) {
        const netId = chain.id;
        Web3Wallet.netInfo = chain;
        Web3Wallet.netId = netId;
        Web3Wallet.netIdError = netId != loadConfig.net_id;
        typeof callback == "function" && (await callback());
      }
    });
  },
};
Vue.prototype.web3Wallet = Web3Wallet;
Vue.prototype.bigNumber = BigNumber;
Vue.prototype.web3Config = loadConfig;

export default Web3Wallet;
