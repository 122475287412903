import "animate.css";
import Vue from "vue";
import VueTypedJs from "vue-typed-js";
Vue.use(VueTypedJs);
import { WOW } from "wowjs";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
import "@/assets/js/rem";
export default {
  name: "index",
  inject: ["reload"],
  computed: {
    ...walletVuex.mapState(["isMobile"])
  },
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW({
        boxClass: "wow",
        animateClass: "animated",
        offset: 0,
        live: true,
        mobile: true
      });
      wow.init();
    });
  },
  methods: {
    onSkip(t) {
      let targetUri = "";
      switch (t) {
        case "twitter":
          targetUri = "https://twitter.com/ReflectionAI";
          break;
        case "telegram":
          targetUri = "https://t.me/reflection_ai";
          break;
      }
      if (targetUri) window.open(targetUri);
    }
  }
};